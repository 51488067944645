<template>
  <div class="operations dp-f" :style="{ top:36 + 'px' }">
    <!-- <div class="but" @click="emit('copyClick',props.item)">
      <img src="@/assets/img/general/copy.png" />
      <div>复制</div>
    </div> -->
    <div class="but" @click="emit('deteleClick',props.item)">
      <img src="@/assets/img/general/detele.png"  />
      <div>删除</div>
    </div>
  </div>
</template>
<script setup>
import { ref, unref, reactive,defineEmits } from "vue";
const props = defineProps({
    // 当前选择组织下标
    item:{
      type: Object,
    },
});
const emit = defineEmits(['copyClick','batchClick','deteleClick'])
</script>
<style lang="scss" scoped>
.operations {
  .but {
    cursor: pointer;
    text-align: center;
    margin: 0 10px;
  }
  z-index: 10;
  position: absolute;
  // bottom: -30px;
  padding: 8px 10px;
  background: #ffffff;
  box-shadow: 1px 1px 5px 0px rgba(193, 193, 193, 0.68);
  border-radius: 2px;
  white-space: nowrap;
}
</style>